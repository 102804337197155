import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage'
import ServicePageS2 from '../ServicePageS2'
import ServiceSinglePage from '../ServiceSinglePage'
import ProjectPage from '../ProjectPage'
import ProjectPageS2 from '../ProjectPageS2'
import ProjectSinglePage from '../ProjectSinglePage'
import PricingPage from '../PricingPage'
import TestimonialPage from '../TestimonialPage'
import BlogPage from '../BlogPage' 
import BlogPageLeft from '../BlogPageLeft' 
import BlogPageFullwidth from '../BlogPageFullwidth'
import BlogDetails from '../BlogDetails' 
import BlogDetailsLeftSiide from '../BlogDetailsLeftSiide' 
import BlogDetailsFull from '../BlogDetailsFull'
import ErrorPage from '../ErrorPage'  
import ContactPage from '../ContactPage' 
import LoginPage from '../LoginPage' 
import SignUpPage from '../SignUpPage' 
import ForgotPassword from '../ForgotPassword' 
      

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/home2' component={Homepage2} />
            <Route path='/home3' component={Homepage3} />
            <Route path='/about' component={AboutPage} />
            <Route path='/service' component={ServicePage} />
            <Route path='/service-s2' component={ServicePageS2} />
            <Route path='/service-single' component={ServiceSinglePage}/>
            <Route path='/project' component={ProjectPage}/>
            <Route path='/project-s2' component={ProjectPageS2}/>
            <Route path='/project-single' component={ProjectSinglePage}/>
            <Route path='/pricing' component={PricingPage}/>
            <Route path='/testimonial' component={TestimonialPage}/>
            <Route path='/404' component={ErrorPage}/>
            <Route path='/contact' component={ContactPage}/> 
            <Route path='/blog' component={BlogPage}/> 
            <Route path='/blog-left-sidebar' component={BlogPageLeft}/>
            <Route path='/blog-fullwidth' component={BlogPageFullwidth}/> 
            <Route path='/blog-single' component={BlogDetails}/>
            <Route path='/blog-single-left-sidebar' component={BlogDetailsLeftSiide}/>
            <Route path='/blog-single-fullwidth' component={BlogDetailsFull}/> 
            <Route path='/login' component={LoginPage}/>
            <Route path='/register' component={SignUpPage}/>
            <Route path='/forgot-password' component={ForgotPassword}/>
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
